import { html, nothing } from 'lit-html';
import { v4 as uuidV4 } from 'uuid';
import { classMap } from 'lit/directives/class-map.js';

export function InputColor({
  value,
  label,
  fnChange,
  isValid,
  uuid,
  isInputColor
}) {
  const inputId = `input-${uuid || uuidV4()}`;
  const classAlign = {
    'flex flex-align-center': isInputColor
  };
  const classInput = {
    'padding-2xs': !isInputColor,
    'width-full border': true,
    'border-color-grey_500': isValid,
    'border-color-error': !isValid
  };
  return html`
    <p class="flex flex-column">
      <label for="${inputId}" class="text-size-s margin-bottom-3xs"
        >${label}</label
      >
      <span class="${classMap(classAlign)}">
        ${isInputColor
          ? nothing
          : html`
              <span aria-hidden="true" class="text-size-s margin-right-3xs">
                #
              </span>
            `}
        <input
          id="${inputId}"
          type="${isInputColor ? 'color' : 'text'}"
          value="${value}"
          class="${classMap(classInput)}"
          @change="${fnChange}"
          maxlength="6"
          aria-invalid="${!isValid}"
        />
      </span>
      ${isInputColor
        ? html`
            <span class="text-size-1xs text-line-1xl" aria-hidden="true">
              ${value}
            </span>
          `
        : nothing}
    </p>
  `;
}
