import { html, render } from 'lit-html';
import {
  isEventClick as isClick,
  isKeyEnterOrKeySpace as isEnterOrSpace
} from 'a11y-key-conjurer';
import i18n from './outbook-rest.i18n.js';
import { GetBaseUrl } from './utils.get-base-url';

const CLASS_DARK = 'is-dark';
const darkElement = document.querySelector('html');

function handleButton(ev) {
  if (isClick(ev) || isEnterOrSpace(ev)) {
    ev.preventDefault();
    darkElement.classList.contains(CLASS_DARK)
      ? unsetDarkMode()
      : setDarkMode();
  }
}

function getTemplate({ isDark }) {
  return html`
    <div class="flex">
      <span
        class="link flex flex-align-center"
        tabindex="0"
        role="button"
        @click="${handleButton}"
        @keydown="${handleButton}"
      >
        <i
          aria-hidden="true"
          class="icon-${isDark ? 'light' : 'dark'}-mode icon-1xs"
        ></i>
        <span class="text-decoration-underline margin-left-3xs"
          >${isDark ? i18n.darkMode.light : i18n.darkMode.dark}</span
        >
      </span>
    </div>
  `;
}

function renderTemplate({ isDark }) {
  render(
    getTemplate({ isDark }),
    document.querySelector('[data-dark-mode-switch]')
  );
}

function toggleDarkMode({ isDark, route }) {
  fetch(`${GetBaseUrl()}/${route}`).then(() => { return true });
  renderTemplate({ isDark });
}

function setDarkMode() {
  toggleDarkMode({ isDark: true, route: 'cookie-mode-dark' });
  darkElement.classList.add(CLASS_DARK);
}

function unsetDarkMode() {
  toggleDarkMode({ isDark: false, route: 'cookie-mode-light' });
  darkElement.classList.remove(CLASS_DARK);
}

export function darkMode() {
  const isDark = document.querySelector('html').classList.contains('is-dark');
  renderTemplate({ isDark });

  window
    .matchMedia('(prefers-color-scheme: dark)')
    .addEventListener('change', event => {
      event.matches ? setDarkMode() : unsetDarkMode();
    });
}
