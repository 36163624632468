import { floatMenu } from './ui.float-menu.js';
import { darkMode } from './ui.dark-mode.js';
import { galleryView } from './ui.gallery-view.js';
import { EuCookies } from './app.eu-cookies.js';
import { PageDialogs } from './ui.modal-dialog-page.js';
import { ToolInitialize } from './app.tools.js';
import {
  isEventClick as isClick,
  isKeyEnter as isEnter
} from 'a11y-key-conjurer';

const anchor = document.querySelector('[data-anchor]');
['click', 'keydown'].forEach(event => {
  anchor.addEventListener(event, ev => {
    if (isClick(ev) || isEnter(ev)) {
      ev.preventDefault();
      const anchorDestiny = document.querySelector(
        ev.currentTarget.getAttribute('href')
      );
      window.scrollTo(0, anchorDestiny.getBoundingClientRect().top);
      anchorDestiny.focus();
    }
  });
});

[
  {
    source: 'search-terms',
    target: 'search-terms-aside'
  },
  {
    source: 'search-terms-aside',
    target: 'search-terms'
  }
].forEach(searchInputs => {
  const source = document.getElementById(searchInputs.source);
  const target = document.getElementById(searchInputs.target);
  source.addEventListener('keyup', () => {
    target.value = source.value;
  });
});

darkMode();
EuCookies();
floatMenu();
PageDialogs();
galleryView();
ToolInitialize();
