import { generatorDni } from './app.tools.generator-dni.js';
import { wcagContrast } from './app.tools.wcag-contrast.js';
import { imageBing } from './app.tools.image-bing.js';

const tools = {
  generatorDni,
  wcagContrast,
  imageBing
};

export function ToolInitialize() {
  const toolPlaceholder = document.querySelector('[data-tool]');
  if (toolPlaceholder) {
    tools[toolPlaceholder.getAttribute('data-tool')]({ toolPlaceholder });
  }
}
