import { EuCookiesCommon } from '../../../../packages/base-assets/js/app.eu-cookies.js';
import { GetBaseUrl } from './utils.get-base-url';

export function EuCookies() {
  EuCookiesCommon({
    routes: {
      accept: `${GetBaseUrl()}/cookie-accepted`
    }
  });
}
