import { html } from 'lit-html';
import i18n from './common.i18n.js';
import './ui.loader.scss';

export function Loader() {
  return html`
    <div class="lds-grid" aria-label="${i18n.loading}">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  `;
}
