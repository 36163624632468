import { html, render } from 'lit-html';
import { generateNie, generateNif } from './utils.generate-dni.js';
import { Button } from '../../../../packages/base-assets/js/ui.component.button.js';
import i18n from './outbook-rest.i18n.js';

function getTemplateBlock({ headingLevel, title, eventFn, value, type }) {
  return html`
    <div class="width-size-full m:width-size-half m:padding-1xs">
      <div class="border border-color-grey_200">
        <div
          role="heading"
          aria-level="${headingLevel}"
          class="margin-bottom-1xs padding-2xs border-bottom border-color-grey_200 font-title text-color-grey_500 dark:text-color-grey_200 text-size-l"
        >
          ${title}
        </div>
        <div class="padding-2xs">
          <p
            class="margin-bottom-1xs font-code text-size-1xl text-align-center"
          >
            ${value}
          </p>
          <div class="flex m:flex-justify-center">
            <div class="margin-right-1xs">
              ${Button({
                text: html`${i18n.dniGenerator.generate}<span class="sr-only"
                    >${type}</span
                  >`,
                icon: 'replay',
                eventFn
              })}
            </div>
            ${Button({
              text: html`${i18n.dniGenerator.copy}<span class="sr-only"
                  >${type}</span
                >`,
              icon: 'copy',
              eventFn: copy(value)
            })}
          </div>
        </div>
      </div>
    </div>
  `;
}

function copy(value) {
  return function () {
    navigator.clipboard
      .writeText(value)
      .then(() => console.log(`Text copied: ${value}`));
  };
}

export function generatorDni({ toolPlaceholder }) {
  const model = {
    headingLevel: 3,
    valueNif: generateNif(),
    valueNie: generateNie()
  };

  function getNewNif() {
    model.valueNif = generateNif();
    renderTemplate();
  }

  function getNewNie() {
    model.valueNie = generateNie();
    renderTemplate();
  }

  function getTemplate() {
    const blocks = [
      {
        headingLevel: model.headingLevel,
        type: 'NIF',
        title: `${i18n.dniGenerator.generator} NIF`,
        eventFn: getNewNif,
        value: model.valueNif
      },
      {
        headingLevel: model.headingLevel,
        type: 'NIE',
        title: `${i18n.dniGenerator.generator} NIE`,
        eventFn: getNewNie,
        value: model.valueNie
      }
    ];

    return html`
      <div class="flex flex-wrap">
        ${blocks.map(block => getTemplateBlock(block))}
      </div>
    `;
  }

  function renderTemplate() {
    render(getTemplate(), toolPlaceholder);
  }
  renderTemplate();
}
