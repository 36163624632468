import { html } from 'lit-html';
import { Icon } from './ui.icon.js';

export function IconText(userProps) {
  const defaults = {
    text: null,
    icon: {
      icon: null,
      color: 'black'
    }
  };
  const { icon, text } = Object.assign(defaults, userProps);
  return html`
    <span class="flex">
      <span>
        ${Icon(icon)}
      </span>
      <span class="margin-left-3xs text-size-m text-line-1xl">
        ${text}
      </span>
    <span>
  `;
}
