import { html } from 'lit-html';
import { openDialog } from '../../../../packages/base-assets/js/ui.modal-dialog.js';
import { isClick, isEnterOrSpace } from '../../../../packages/base-assets/js/utils/accessibility.js';

function getContent({ src, title }) {
  return html`
    <p class="text-size-1xl margin-bottom-1xs">${title}</p>
    <div class="flex-space-self-all">
      <div
        class="height-full width-full flex flex-align-center flex-justify-center"
      >
        <img src="${src}" alt="" class="max-width-full max-height-full" />
      </div>
    </div>
  `;
}

export const galleryView = function () {
  const galleryItems = document.querySelectorAll(
    '.ngg-galleryoverview [data-src]'
  );
  if (galleryItems.length) {
    galleryItems.forEach(item => {
      ['click', 'keydown'].forEach(event => {
        item.addEventListener(event, ev => {
          if (isClick(ev) || isEnterOrSpace(ev)) {
            ev.preventDefault();
            const target = ev.currentTarget;
            const imageUrl = target.getAttribute('data-src');
            openDialog(ev, {
              actionOpen: function (cb) {
                const image = new Image();
                image.onload = function () {
                  const htmlContent = getContent({
                    src: imageUrl,
                    title: target.getAttribute('data-title')
                  });
                  cb({ htmlContent });
                };
                image.src = imageUrl;
              }
            });
          }
        });
      });
    });
  }
};
