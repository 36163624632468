export default {
  dniGenerator: {
    generate: 'Generar',
    generator: 'Generador',
    copy: 'Copiar'
  },
  wcag2Contrast: {
    contrast: 'Contraste',
    cases: {
      title: 'Nivel [0] y texto [1]: requiere contraste [2]',
      size: {
        small: 'normal',
        large: 'grande'
      },
      result: {
        valid: 'Suficiente',
        notValid: 'Insuficiente'
      }
    }
  },
  imageBing: {
    newWindow: 'Abre en ventana nueva'
  }
};
