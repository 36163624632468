import { EuCookiesConjurer } from 'eu-cookies-conjurer';
import { GetBaseUrl } from './utils.get-base-url';

export function EuCookies() {
  EuCookiesConjurer({
    routes: {
      accept: `${GetBaseUrl()}/cookie-accepted`
    }
  });
}
