import { isClick, isEnterOrSpace } from './utils/accessibility.js';

function acceptEuCookies({ cookieLayout, routes }) {
  fetch(routes.accept)
    .then(response => response.json())
    .then(() => {
      cookieLayout.remove();
    });
}

export function EuCookiesCommon({ routes }) {
  const cookieLayout = document.getElementById('eu-cookies-directive');
  if (cookieLayout) {
    const buttonAccept = cookieLayout.querySelector('#accept-eu-cookies');
    ['click', 'keydown'].forEach(event => {
      buttonAccept.addEventListener(event, ev => {
        if (isClick(ev) || isEnterOrSpace(ev)) {
          ev.preventDefault();
          acceptEuCookies({ cookieLayout, routes });
        }
      });
    });
  }
}
