export function formatString(str, args) {
  if (typeof str !== 'string') {
    return str;
  }

  const parsedArgs = args || [];

  return str.replace(/\[(\d+)\]/g, function formatStringMatch(matched, num) {
    return typeof parsedArgs[num] !== 'undefined' ? parsedArgs[num] : matched;
  });
}
