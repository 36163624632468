export default {
  loading: 'Cargando',
  modalDialog: {
    buttonClose: 'Cerrar'
  },
  darkMode: {
    dark: 'Modo oscuro',
    light: 'Modo claro'
  }
};
