import { hex, wcagContrastValue } from 'a11y-color-contrast';

function contrastRatio(colors) {
  const calculatedContrast = wcagContrastValue(hex(colors[0]), hex(colors[1]));
  return {
    calculatedContrast,
    results: [
      {
        required: '4.5',
        compliant: calculatedContrast >= 4.5,
        weight: 'regular',
        size: 'small',
        sizeNum: 14,
        level: 'AA'
      },
      {
        required: '7',
        compliant: calculatedContrast >= 7,
        size: 'small',
        sizeNum: 14,
        level: 'AAA'
      },
      {
        required: '3',
        compliant: calculatedContrast >= 3,
        size: 'large',
        sizeNum: 18,
        level: 'AA'
      },
      {
        required: '4.5',
        compliant: calculatedContrast >= 4.5,
        size: 'large',
        sizeNum: 18,
        level: 'AAA'
      }
    ]
  };
}

export function getContrast(colors) {
  return contrastRatio(colors);
}
