export function isClick(ev) {
  return ev.type === 'click';
}

export function isEnter(ev) {
  return ev.key === 'Enter';
}

export function isSpace(ev) {
  return ev.key === ' ';
}

export function isEscape(ev) {
  return ev.key === 'Escape';
}

export function isEnterOrSpace(ev) {
  return isEnter(ev) || isSpace(ev);
}
