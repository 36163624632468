import { html } from 'lit-html';
import { openDialog } from '../../../../packages/base-assets/js/ui.modal-dialog.js';
import {
  isEventClick as isClick,
  isKeyEnterOrKeySpace as isEnterOrSpace
} from 'a11y-key-conjurer';
import { unsafeHTML } from 'lit/directives/unsafe-html.js';

export function PageDialogs() {
  const dialogs = document.querySelectorAll('[data-modal-dialog]');

  Array.from(dialogs).forEach(item => {
    ['click', 'keydown'].forEach(event => {
      item.addEventListener(event, ev => {
        if (isClick(ev) || isEnterOrSpace(ev)) {
          ev.preventDefault();
          const url = `${ev.currentTarget.getAttribute('data-href')}`;
          openDialog(ev, {
            actionOpen: function (cb) {
              fetch(url)
                .then(response => response.json())
                .then(({ model }) => {
                  cb({
                    htmlContent: html`
                      <div class="padding-1xs">
                        <h1
                          class="font-title text-color-grey_500 dark:text-color-grey_200 margin-bottom-1xs text-size-2xl"
                        >
                          ${model.content.title.rendered}
                        </h1>
                        <div class="entry-content">
                          ${unsafeHTML(model.content.content.rendered)}
                        </div>
                      </div>
                    `
                  })
                });
            }
          });
        }
      });
    });
  });
}
