const lettersFinal = {
  0: 'T',
  1: 'R',
  2: 'W',
  3: 'A',
  4: 'G',
  5: 'M',
  6: 'Y',
  7: 'F',
  8: 'P',
  9: 'D',
  10: 'X',
  11: 'B',
  12: 'N',
  13: 'J',
  14: 'Z',
  15: 'S',
  16: 'Q',
  17: 'V',
  18: 'H',
  19: 'L',
  20: 'C',
  21: 'K',
  22: 'E'
};

const lettersStart = {
  0: 'X',
  1: 'Y',
  2: 'Z'
};

function randomInteger({ min, max }) {
  const number = Math.random() * (max - min) + min;
  return Math.floor(number);
}

export function generateNif() {
  const mainNumber = randomInteger({ max: 99999999, min: 1 });
  const letterFinal = lettersFinal[mainNumber % 23];
  return `${mainNumber}${letterFinal}`;
}

export function generateNie() {
  const mainNumber = randomInteger({ max: 9999999, min: 1 });
  const numberStartLetter = randomInteger({ max: 2.9999, min: 0 });
  const mainNumberTotal = parseInt(`${numberStartLetter}${mainNumber}`);
  const letterFinal = lettersFinal[mainNumberTotal % 23];
  return `${lettersStart[numberStartLetter]}${mainNumber}${letterFinal}`;
}
