const CLASSES_POSITION = ['m:position-fixed', 'm:top-2xs'];
const CLASSES_STICKED_HEADER = ['hidden'];
const CLASSES_HEADER = ['visibility-hidden'];

function setPosition({ aside, stickedHeader, header }) {
  return function () {
    if (aside.parentNode.getBoundingClientRect().top <= 8) {
      aside.classList.add(...CLASSES_POSITION);
      stickedHeader.classList.remove(...CLASSES_STICKED_HEADER);
      header.classList.add(...CLASSES_HEADER);
      header.setAttribute('aria-hidden', true);
    } else {
      aside.classList.remove(...CLASSES_POSITION);
      stickedHeader.classList.add(...CLASSES_STICKED_HEADER);
      header.classList.remove(...CLASSES_HEADER);
      header.removeAttribute('aria-hidden');
    }
  };
}

export function floatMenu() {
  const aside = document.querySelector('[data-aside]');
  const stickedHeader = document.querySelector('[data-sticky-header]');
  const header = document.querySelector('[data-header]');
  const onScroll = setPosition({ aside, stickedHeader, header });
  window.addEventListener('scroll', onScroll);
  onScroll();
}
