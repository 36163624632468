import { html } from 'lit-html';

export function Button({ text, eventFn, icon }) {
  return html`
    <button class="button" @click="${eventFn}">
      ${icon
        ? html`<i
            aria-hidden="true"
            class="button__icon icon-${icon} icon-2xs"
          ></i>`
        : ''}
      <span class="button__text">${text}</span>
    </button>
  `;
}
