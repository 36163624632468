import { html } from 'lit-html';

export function Icon(userProps) {
  const defaults = {
    icon: null,
    color: 'black'
  };
  const { icon, color } = Object.assign(defaults, userProps);
  return html`
    <i
      aria-hidden="true"
      class="icon-${icon} icon-1xs text-color-${color}"
    ></i>
  `;
}
